import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from 'src/components/bio'
import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import { renderAst } from 'src/utils/renderAst.js'
import { rhythm, scale } from 'src/utils/typography'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const seoDescription = post.frontmatter.description || post.seoSnippet;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <article>
          <SEO
            title={post.frontmatter.title}
            description={seoDescription}
            keywords={post.frontmatter.tags || []} />
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={this.props.pageContext.slug}
          >
            <h1>{post.frontmatter.title}</h1>
          </Link>
          <div
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
              marginTop: rhythm(-0.5),
            }}
          >
            {
              (post.frontmatter.updates && post.frontmatter.updates.length) ? (
                <details>
                  <summary><span className="publishDate">Published on {post.frontmatter.date}. Most recently updated on {post.frontmatter.updates.slice(-1)[0].date}.</span></summary>
                  <ol className="updateHistory">
                    <li>
                      <span className="date">{post.frontmatter.date}</span>
                      &nbsp;-&nbsp;
                      <span className="summary">Initial publication</span>
                    </li>
                    {
                      post.frontmatter.updates.map(({date, summary, minor}) => {
                        return (
                          <li>
                            <span className="date">{date}</span>
                            &nbsp;-&nbsp;
                            <span className="summary">{summary}{minor ? " (minor change)" : ""}</span>
                          </li>
                        );
                      })
                    }
                  </ol>
                </details>
              ) : (
                <span className="publishDate">Published {post.frontmatter.date}.</span>
              )
            }
          </div>
          <div className="articleBody">
            {
              renderAst(post.htmlAst)
            }
          </div>
          <hr
            style={{
              marginBottom: rhythm(1),
              clear: 'both',
            }}
          />
        </article>
        <Bio />

        <nav className="postArticleNav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      seoSnippet
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        updates 
        {
          date(formatString: "YYYY-MM-DD")
          summary
          minor
        }
        tags
      }
    }
  }
`
